/**
 *
 * 로또 추첨기
 *
 * @constructor
 */
const Lotto = () => {
  return <>
    로또 추첨기 알고리즘

  </>
}

export default Lotto;
